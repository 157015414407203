import React from "react"
import Layout from "../components/layout"
import PageTransition from "gatsby-plugin-page-transitions"
import { useStateValue } from "../contextProvider/StateProvider"
import PageTitle from "../components/PageTitle"
import SEO from "../components/seo"
import { Grid } from "@material-ui/core"
import ContactInformation from "../components/ContactInformation"
import ContactForm from "../components/ContactForm"
import styled from "@emotion/styled"


const ContactContainer = styled.div`
  width: 70vw;
  overflow: hidden;
  margin: 0px auto 70px;
`

const Contact = () => {
  const [{ color }] = useStateValue()
  return (
    <Layout>
      <SEO title="Contact" />
      <ContactContainer>
        <PageTransition
          transitionStyles={{
            entering: { opacity: "0.2" },
            entered: { opacity: "1" },
            exiting: { opacity: "0" },
          }}
          transitionTime={300}
        >
          <PageTitle
            frontTitle={"Get In"}
            coloredTitle={"touch"}
            color={color}
          />
          <Grid container spacing={3}>
            <ContactInformation color={color} />
            <ContactForm color={color} />
          </Grid>
        </PageTransition>
      </ContactContainer>
    </Layout>
  )
}

export default Contact
