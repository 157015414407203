import React from "react"

import "../css/pageTitle.css"
import { css } from "@emotion/core"
const PageTitle = ({ backTitle, frontTitle, coloredTitle, color }) => {

  return (
    <div className="pageTitle">
      <h2 className="pageTitle__front" style={{textTransform: 'uppercase'}}>
        <span>{frontTitle}</span>
        <span
          css={css`
            margin-left: 10px;
            color: ${color};
          `}
        >
          {coloredTitle}
        </span>
      </h2>
    </div>
  )
}

export default PageTitle
