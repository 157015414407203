import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import styled from "@emotion/styled"
import emailjs from "emailjs-com"
import Recaptcha from "react-recaptcha"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const FormContainer = styled.form``

const FormDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 880px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Input = styled.input`
  flex: 1;
  display: block;
  border-radius: 25px;
  background: rgb(36, 35, 35);
  outline: none;
  border: none;
  padding: 13px 15px;
  color: white;
  margin: 15px 0;
  border:2px solid transparent;

  &:focus {
    border: ${props => `2px solid ${props.color}`};
  }

  @media (min-width: 880px) {
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
`


const MessageBox = styled.div`
  width: 100%;
`

const TextArea = styled.textarea`
  width: inherit;
  height: 150px;
  padding: 15px;
  background: rgb(36, 35, 35);
  color: white;
  outline: none;
  border: none;
  border-radius: 25px;
  max-width: inherit;
  border: 2px solid transparent;
  &:focus {
    border: ${props => `2px solid ${props.color}`};
  }
`
const SubmitButton = styled.button`
  margin-top: 25px;
  padding: 10px 25px;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 650;
  background: ${props => props.color};
  &:hover {
    opacity: 0.7;
  }
`

const ContactForm = ({ color }) => {
  const [recapthcaVerified, setRecaptchaVerified] = useState(false)

  const recaptchaVerifiedFunc = response => {
    setRecaptchaVerified(true)
  }

  const sendEmail = e => {
    e.preventDefault()
    if (!recapthcaVerified) {
      toast.error("Please verify the recapthca!")
      return
    }

    const emailForm = async () => {
      try {
        const res = await emailjs.sendForm(
          "service_vbb07ti",
          "template_alky6j6",
          e.target,
          "user_JMVK0HjzFZJ4JTUm4WSga"
        )
        toast.dark("Congratulations! You have successfully sent email")
      } catch(error) {
        console.log(error)
        toast.error("Error Sending mail!")
      }
    }
    toast.warning("Please wait whie form is being submitted!")
    emailForm()
    e.target.reset()
  }

  const callback = () => {
    console.log("Done")
  }

  return (
    <Grid item sm={12} lg={8} style={{ width: "100%" }}>
      <FormContainer
        className="contactForm"
        name="contact"
        onSubmit={sendEmail}
        method="POST"
      >
        <FormDetails>
          <Input
            type="text"
            placeholder="YOUR NAME"
            name="name"
            required
            color={color}
          />
          <Input
            type="email"
            placeholder="YOUR EMAIL"
            name="email"
            required
            color={color}
          />
          <Input
            type="text"
            placeholder="YOUR SUBJECT"
            name="subject"
            required
            color={color}
          />
        </FormDetails>
        <MessageBox>
          <TextArea required placeholder="YOUR MESSAGE" name="message" color={ color }/>
        </MessageBox>
        {/* Recaptcha Starts */}
        <Recaptcha
          sitekey="6LdHR9oZAAAAAO7IQAgy-JUSmPDhCFQFtai5y0kx"
          render="explicit"
          onloadCallback={callback}
          theme="dark"
          verifyCallback={recaptchaVerifiedFunc}
        />
        {/* Recaptcha Ends */}
        <SubmitButton type="submit" value="Send" color={color}>
          SEND MESSAGE
        </SubmitButton>
      </FormContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  )
}

export default ContactForm
