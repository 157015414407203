import React from "react"
import { Grid, Avatar } from "@material-ui/core"
import DraftsIcon from "@material-ui/icons/Drafts"
import PhoneIcon from "@material-ui/icons/Phone"
import FacebookIcon from "@material-ui/icons/Facebook"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"
import GitHubIcon from "@material-ui/icons/GitHub"
import styled from "@emotion/styled"

const H3 = styled.h3`
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 25px;
`

const ContactInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`
const ContactInfo = styled.div`
  margin-left: 15px;
`

const P = styled.p``

const BoldText = styled.div`
  font-weight: 600;
`

const AvatarContainer = styled.div`
  display: flex;
`
const AvatarLink = styled.a`
  margin-right: 15px;
  cursor: pointer;
`

const StyledAvatar = styled(Avatar)`
  background: rgb(49, 48, 48);
  transition: 0.3s;
  &:hover {
    background: ${props => props.color} !important;
  }
`

const ContactInformation = ({ color }) => {
  return (
    <Grid item sm={12} lg={4}>
      <H3>Don't be shy!</H3>
      <p>
        Feel free to get in touch with me. I am always open to discussing new
        projects, creative ideas or opportunities to be part of your visions.
      </p>

      {/* ---------------------------- */}
      <ContactInfoContainer>
        <DraftsIcon style={{ color, fontSize: 30 }} />
        <ContactInfo>
          <BoldText>Mail Me</BoldText>
          <div>ayushmainali11@gmail.com</div>
        </ContactInfo>
      </ContactInfoContainer>
      {/* ---------------------- */}

      <ContactInfoContainer>
        <PhoneIcon style={{ color, fontSize: 30 }} />
        <ContactInfo>
          <BoldText>Call Me</BoldText>
          <div>Availiable Upon Request</div>
        </ContactInfo>
      </ContactInfoContainer>
      <AvatarContainer>
        <AvatarLink
          href="https://www.facebook.com/profile.php?id=100011615657913"
          target="_blank"
        >
          <StyledAvatar color={color}>
            <FacebookIcon/>
          </StyledAvatar>
        </AvatarLink>
        <AvatarLink href="https://github.com/AyushMainali123" target="_blank">
          <StyledAvatar color={color}>
            <GitHubIcon />
          </StyledAvatar>
        </AvatarLink>
        <AvatarLink href="https://www.linkedin.com/in/ayush-mainali-112434198/">
          <StyledAvatar color={color}>
            <LinkedInIcon />
          </StyledAvatar>
        </AvatarLink>
        <AvatarLink href="https://twitter.com/AyushMainali4">
          <StyledAvatar color={color}>
            <TwitterIcon />
          </StyledAvatar>
        </AvatarLink>
      </AvatarContainer>
    </Grid>
  )
}

export default ContactInformation
